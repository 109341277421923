import axios from "axios";
import { DaySearch, GETConfigResponseBody, GETDataResponseBody, SemaphoreConfig, TokenResponseBody } from "./interfaces";
import { readJWT } from "./jwt";


const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

function authorization() {
	return { headers: { Authorization: `Bearer ${readJWT()}` } };
}

// App
export function refreshToken() {
	return axiosClient.get<TokenResponseBody>(`/refresh_token`, authorization());
}

// Página Login
export function login(username: string, password: string) {
	return axiosClient.post<TokenResponseBody>(
		`/login`,
		{ username: username, password: password },
	);
}

// Página Home
export function getHomeData(daySearch: DaySearch) {
	return axiosClient.get<GETDataResponseBody>(
		daySearch.enabled ? `/registers_since?days=${daySearch.days}` : "/last_registers",
		authorization(),
	);
}

export function getSemaphoreConfig() {
	return axiosClient.get<GETConfigResponseBody>(`/config`, authorization());
}

export function postSemaphoreConfig(newConfig: SemaphoreConfig) {
	return axiosClient.post(
		`/config`,
		newConfig,
		authorization(),
	);
}

export function postSetAlarms(clientID: number, meterID: number, enableAlarms: boolean) {
	return axiosClient.post(
		`/set_alarms`,
		{ clientID: clientID, meterID: meterID, enableAlarms: enableAlarms },
		authorization(),
	);
}