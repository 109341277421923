import meterconLogo from "../assets/metercon_logo.png";
import meterconLogoNavidad from "../assets/metercon_logo_n.png";
import meterconLogoFiestasPatrias from "../assets/metercon_logo_p.png";

/**
 * El "Simón Dice" me pidió que hiciera esto.
 * - Pancho
 */
export default function getLogoSource(): any {
  const currentMonth = new Date().getMonth() + 1;
  switch (currentMonth) {
    case 9: return meterconLogoFiestasPatrias;
    case 12: return meterconLogoNavidad;
    default: return meterconLogo;
  }
}