import { useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";

import HomeScreen from "./homeScreen/HomeScreen";
import { refreshToken } from "./utils/api";
import { deleteJWT, extractAdminClaims, readJWT, storeJWT } from "./utils/jwt";
import { AdminState } from "./utils/interfaces";
import LoginScreen from "./loginScreen/LoginScreen";


const HOUR = 1000 * 60 * 60;

function App() {
  const [adminState, setAdminState] = useState<AdminState>({
    isValidating: true,
    admin: null,
  });

  function refreshAndSetToken() {
    const jwtString = readJWT();

    if (jwtString === "") {
      setAdminState({ isValidating: false, admin: null });
      return;
    }

    refreshToken()
      .then(res => {
        storeJWT(res.data.token);
        setAdminState({
          isValidating: false,
          admin: extractAdminClaims(jwtString),
        });
      })
      .catch(err => {
        // Solo si hubo respuesta del servidor, resetear JWT
        // Esto soluciona un error con las tablets que fallan al conectar
        // con el backend y eliminan su token prematuramente
        if (err.response) {
          deleteJWT();
          setAdminState({ isValidating: false, admin: null });
        }
      });
  }

  useEffect(() => {
    refreshAndSetToken();
    setInterval(refreshAndSetToken, HOUR);
  }, []);

  return (
    <ChakraProvider>
      {(adminState.isValidating) ?
        <p>Cargando...</p>
      : (adminState.admin === null) ?
        <LoginScreen setAdminState={setAdminState} />
      :
        <HomeScreen />}
    </ChakraProvider>
  );
}

export default App;
