import React from "react";
import {
  Box,
  Heading, Text,
  HStack, VStack,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
  Button,
  Select,
  Radio, RadioGroup,
  Input,
} from "@chakra-ui/react";

import { postSemaphoreConfig } from "../../../utils/api";
import { SemaphoreConfig, SemaphoreConfigBooleanFields, SemaphoreConfigFloatFields, SemaphoreConfigIntFields, SemaphoreConfigStringFields } from "../../../utils/interfaces";


interface ConfigFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  config: SemaphoreConfig;
  setConfig: (newConfig: React.SetStateAction<SemaphoreConfig>) => void;
};

export default function ConfigFormModal({ isOpen, onClose, config, setConfig }: ConfigFormModalProps): JSX.Element {
  var localConfig: SemaphoreConfig = JSON.parse(JSON.stringify(config));

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    localConfig.mailRecipients = localConfig.mailRecipients.split(" ").join("");

    /**
     * POST /semaphore/config llama a la función POSTConfig, definida en
     * semaphoreback/sgapi/post_config.go
     */
    postSemaphoreConfig(localConfig)
      .then(_ => {
        // JSON.parse(JSON.stringify(...)) hace una deepcopy
        config = JSON.parse(JSON.stringify(localConfig));
        setConfig(localConfig);
        onClose();
      })
      .catch(_ => { });
  }

  // Función necesaria para desactivar el scrolling en los campos numéricos
  // Por defecto el scrolling cambia el valor en esos campos, lo cual es molesto
  // Esta función desactiva eso
  function disableScroll(event: React.WheelEvent<HTMLInputElement>) {
    (event.target as HTMLElement).blur();
  }

  /* 
  Nota para todas las funciones onChange:
  event.target se refiere al input en sí (Input, TimeInput, Radio)
  event.target.name es el parámetro name pasado al input
  event.target.value es el valor actual almacenado por el input
  En el caso de Select, se debe usar event.target.selectedOptions para acceder
  a las opciones disponibles a seleccionar
  */

  function onChangeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    localConfig[event.target.name as keyof SemaphoreConfigBooleanFields] = event.target.selectedOptions[0].value === "true";
  }

  function onChangeInt(event: React.ChangeEvent<HTMLInputElement>) {
    localConfig[event.target.name as keyof SemaphoreConfigIntFields] = parseInt(event.target.value);
  }

  function onChangeFloat(event: React.ChangeEvent<HTMLInputElement>) {
    localConfig[event.target.name as keyof SemaphoreConfigFloatFields] = parseFloat(event.target.value);
  }

  function onChangeBoolean(event: React.ChangeEvent<HTMLInputElement>) {
    localConfig[event.target.name as keyof SemaphoreConfigBooleanFields] = event.target.value === "true";
  }

  function onChangeString(event: React.ChangeEvent<HTMLInputElement>) {
    localConfig[event.target.name as keyof SemaphoreConfigStringFields] = event.target.value;
  }

  /* Funciones auxiliares para evitar repetir tanto código */

  function MyHeading({ heading }: { heading: string }): JSX.Element {
    return <Heading as="h3" size="md" py={4}>{heading}</Heading>;
  }

  function MyLabel({ label }: { label: string }): JSX.Element {
    return <Box w="160px"><Text><b>{label}</b></Text></Box>;
  }

  function FloatInput({ label, name }: { label: string, name: keyof SemaphoreConfigFloatFields }): JSX.Element {
    return (
      <HStack>
        <MyLabel label={label} />
        <Input
          name={name} defaultValue={localConfig[name]} placeholder="0.000"
          onChange={onChangeFloat} onWheel={disableScroll}
          type="number" step="any" variant="flushed" htmlSize={40} width="auto"
        />
      </HStack>
    );
  }

  function TimeInput({ label, color }: { label: string, color: "yellow" | "red" | "cyan" }) {
    function UnitField({ unit }: { unit: "days" | "hours" | "minutes" | "seconds" }) {
      // Asumiendo que namePrefix === "yellowTime":
      // Ejemplo con unit === "hours": se asigna initial === "h",
      // name === "yellowTimeHours", placeholder === "hh" y max === 23
      const initial = unit.charAt(0);
      const name = `${color}Time${initial.toUpperCase()}${unit.slice(1)}`;
      const placeholder = initial + initial;
      const max = (unit === "days") ? 99 : ((unit === "hours") ? 23 : 59);
      return (
        <HStack spacing={0}>
          <Input
            name={name} defaultValue={localConfig[name as keyof SemaphoreConfigIntFields]} placeholder={placeholder}
            min={0} max={max}
            onChange={onChangeInt} onWheel={disableScroll}
            type="number" variant="flushed" maxW={5}
          />
          <Text>{initial}</Text>
        </HStack>
      );
    }

    return (
      <HStack>
        <MyLabel label={label} />
        <HStack spacing={6}>
          <UnitField unit="days" />
          <UnitField unit="hours" />
          <UnitField unit="minutes" />
          <UnitField unit="seconds" />
        </HStack>
      </HStack>
    );
  }

  return (
    <Modal size="xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configuración del semáforo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={onSubmit} method="post">
            <VStack align="left" spacing={1}>
              {/* Ángulos, corrientes y voltajes */}
              <MyHeading heading="Corrientes" />
              <FloatInput label="Umbral mínimo" name="minCurrent" />

              <MyHeading heading="Ángulos" />
              <FloatInput label="Mínimo permitido" name="minAngle" />
              <FloatInput label="Máximo permitido" name="maxAngle" />

              <MyHeading heading="Voltajes" />
              <FloatInput label="Mínimo permitido" name="minVoltage" />
              <FloatInput label="Máximo permitido" name="maxVoltage" />

              {/* Tiempos */}
              <MyHeading heading="Tiempos" />
              <TimeInput label="Amarillo" color="yellow" />
              <TimeInput label="Rojo" color="red" />
              <TimeInput label="Celeste" color="cyan" />

              {/* Correos */}
              <MyHeading heading="Correos" />
              <HStack>
                <MyLabel label="Enviar correos" />
                <RadioGroup name="mailEnable" defaultValue={localConfig.mailEnable.toString()} >
                  <HStack spacing={4}>
                    <Radio value="true" onChange={onChangeBoolean}>Sí</Radio>
                    <Radio value="false" onChange={onChangeBoolean}>No</Radio>
                  </HStack>
                </RadioGroup>
              </HStack>
              <HStack>
                <MyLabel label="Destinatarios" />
                <Input name="mailRecipients" defaultValue={localConfig.mailRecipients} onChange={onChangeString} placeholder="Correos separados por punto y coma (;)" variant="flushed" htmlSize={40} width="auto" />
              </HStack>
              <HStack>
                <MyLabel label="Hora de envío" />
                <HStack spacing={0}>
                  <Input type="time" name="mailTime" defaultValue={localConfig.mailTime} onChange={onChangeString} variant="flushed" />
                </HStack>
              </HStack>
              <HStack pb="4">
                <MyLabel label="Método de envío" />
                <Select name="useSendGrid" defaultValue={localConfig.useSendGrid.toString()} onChange={onChangeSelect} variant="flushed" w={40} width="auto" >
                  <option value="false">Correo directo desde Desarrollo</option>
                  <option value="true">Monitor SendGrid</option>
                </Select>
              </HStack>

              <Button type="submit" shadow="lg">Actualizar</Button>
            </VStack>

          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

