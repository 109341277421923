import React, { useEffect, useState } from "react";

import { Center, Text } from "@chakra-ui/react";
import unixTimeToDateTime from "../../../utils/unixTimeToDateTime";
import { ServerStatus } from "../../../utils/interfaces";


interface ServerClockParams {
  serverStatus: ServerStatus;
}

export default function ServerClock({ serverStatus }: ServerClockParams) {
  const [updatedServerUnixTime, setUpdatedServerUnixTime] = useState(serverStatus.unixTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (serverStatus.hasError) {
        setUpdatedServerUnixTime(serverStatus.unixTime);
      } else {
        setUpdatedServerUnixTime(updatedServerUnixTime + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  });
  useEffect(() => {
    setUpdatedServerUnixTime(serverStatus.unixTime);
  }, [serverStatus.unixTime]);

  function getCurrentTime(): string {
    if (serverStatus.isLoading || updatedServerUnixTime === 0) {
      return "";
    }
    return unixTimeToDateTime(updatedServerUnixTime);
  }

  return (
    <Center
      w={{ "base": "242px", "sm": "266px", "md": "346px", "lg": "360px" }}
      py="2"
      borderRadius="md"
      bg={serverStatus.isLoading ? "blue.300" : serverStatus.hasError ? "red.300" : "green.300"}
      color={serverStatus.isLoading ? "blue.800" : serverStatus.hasError ? "red.800" : "green.800"}
    >
      <Text display={{ "base": "block", "sm": "none" }}>
        <b>{serverStatus.isLoading ? "Cargando..." : serverStatus.hasError ? "¡Error! " : "¡OK! "}</b> {getCurrentTime()}
      </Text>
      <Text display={{ "base": "none", "sm": "block" }}>
        <b>{serverStatus.isLoading ? "Cargando..." : serverStatus.hasError ? "¡Sin conexión! " : "¡Servidor OK! "}</b> {getCurrentTime()}
      </Text>
    </Center>
  );
}