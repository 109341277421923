import { RefObject, useRef } from "react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, AlertDialogOverlay, Box, Button, Center, Flex, Heading, SimpleGrid, Text, VStack, IconButton, useDisclosure } from "@chakra-ui/react";
import { BellIcon, MoonIcon } from "@chakra-ui/icons";

import { postSetAlarms } from "../../utils/api";
import { Meter } from "../../utils/interfaces";


interface MeterSectionProps {
  allMeters: Meter[];
  allEnabledMeterIndices: number[];
  badEnabledMeterIndices: number[];
  disabledMeterIndices: number[];
  reloadDeviceIndices: (updateReloadKey: boolean) => void,
};

interface MeterBoxProps {
  meter: Meter;
  setAlarms: () => Promise<void>;
};

/**
 * MeterSection muestra primero la información de todos los dispositivos
 * caídos (del arreglo badMeters), y luego la información de todos los
 * dispositivos, buenos o caídos (del arreglo allMeters).
 * 
 * Ambos arreglos, allMeters y badMeters, son arreglos de objetos con
 * la información de cada dispositivo. Por cada objeto en los arreglos,
 * se despliega una MeterBox en la página con su información.
 */
export default function MeterSection({ allMeters, allEnabledMeterIndices, badEnabledMeterIndices, disabledMeterIndices, reloadDeviceIndices }: MeterSectionProps): JSX.Element {
  function makeBoxes(deviceIndices: number[]) {
    return deviceIndices.map(i => {
      const meter = allMeters[i];
      const { clientID, meterID, enableAlarms } = meter;

      const setAlarms = async () => {
        return postSetAlarms(clientID, meterID, !enableAlarms)
          .then(() => {
            allMeters[i].enableAlarms = !enableAlarms;
            reloadDeviceIndices(true);
          });
      };

      return <MeterBox meter={meter} key={i} setAlarms={setAlarms} />;
    });
  }

  return (
    <>
      <SimpleGrid columns={{ "base": 1, "sm": 2, "md": 3, "lg": 4, "xl": 5 }} rowGap={{ "base": 2, "xl": 3, "2xl": 4 }} columnGap={{ "sm": 3, "xl": 5, "2xl": 8 }}>
        {makeBoxes(badEnabledMeterIndices)}
      </SimpleGrid>

      <Heading textAlign="center" size={{ "base": "lg", "lg": "xl" }}>Todos los medidores habilitados: {allEnabledMeterIndices.length}</Heading>
      <SimpleGrid columns={{ "base": 1, "sm": 2, "md": 3, "lg": 4, "xl": 5 }} rowGap={{ "base": 2, "xl": 3, "2xl": 4 }} columnGap={{ "sm": 3, "xl": 5, "2xl": 8 }}>
        {makeBoxes(allEnabledMeterIndices)}
      </SimpleGrid>

      {disabledMeterIndices.length > 0 &&
        <>
          <Heading textAlign="center" size={{ "base": "lg", "lg": "xl" }}>Medidores deshabilitados: {disabledMeterIndices.length}</Heading>
          <SimpleGrid columns={{ "base": 1, "sm": 2, "md": 3, "lg": 4, "xl": 5 }} rowGap={{ "base": 2, "xl": 3, "2xl": 4 }} columnGap={{ "sm": 3, "xl": 5, "2xl": 8 }}>
            {makeBoxes(disabledMeterIndices)}
          </SimpleGrid>
        </>
      }
    </>
  )
}

/**
 * MeterBox muestra toda la información relevante de un dispositivo. Cambia
 * de color dependiendo de su estado: más información en el archivo
 * homeScreen/generateMeterData.js.
 */
function MeterBox({ meter, setAlarms }: MeterBoxProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as RefObject<HTMLButtonElement>;

  const { clientID, meterID, companyID, domainName, boxBGColor, boxHeading, boxTime, subBoxBGColors, subBoxTextColors, subBoxCurrentTexts, subBoxAngleTexts, enableAlarms } = meter;
  var newBoxBGColor = boxBGColor;
  var newSubBoxBGColors = subBoxBGColors.map(c => c);
  if (!enableAlarms) {
    newBoxBGColor = (
      boxBGColor.substring(0, boxBGColor.length - 3) +
      (boxBGColor.startsWith("gray") ? "200" : "100")
    );
    for (var i = 0; i < 3; i++) {
      const subColor = subBoxBGColors[i];
      newSubBoxBGColors[i] = (
        subColor.substring(0, subColor.length - 3) +
        (subColor.startsWith("gray") ? "100" : "50")
      );
    }
  }
  const link = `http://${domainName}/public/v2/meters/${companyID}/${clientID}/${meterID}`;

  return (
    <VStack
      w={{ "base": "250px", "sm": "210px", "md": "218px", "xl": "226px", "2xl": "270px" }}
      spacing={0}
      shadow="lg"
    >

      <Box
        position="relative"
        w="full"
        pt={{ "base": 1, "xl": 2 }}
        pb={{ "base": 2, "xl": 3 }}
        borderTopRadius="lg"
        bg={newBoxBGColor}
        color={enableAlarms ? "white" : "gray.500"}
      >
        <IconButton
          icon={enableAlarms ? <MoonIcon /> : <BellIcon />}
          aria-label={enableAlarms ? "Desactivar alarma" : "Activar alarma"}
          position="absolute"
          top={{"base": 3, "2xl": 4}}
          left={{"base": 3, "2xl": 5}}
          size="xs"
          colorScheme={enableAlarms ? "blue" : "yellow"}
          onClick={onOpen}
          shadow="md"
          border="1px"
        />
        <Heading textAlign="center" as="h3" size="lg" pt="1" noOfLines={1}>
          <a href={link}>{boxHeading}</a>
        </Heading>
        <Text align="center" lineHeight={1.2}>
          Última comunicación:<br />{boxTime}
        </Text>
      </Box>

      {/* MeterSubBox */}
      <Flex w="full" h="54px" gap="1px" pt="1px" bg={enableAlarms ? "gray.500" : "gray.300"} borderBottomRadius="lg">
        {[0, 1, 2].map(i => (
          <Center
            w="full"
            h="54px"
            bg={newSubBoxBGColors[i]}
            color={enableAlarms ? subBoxTextColors[i] : "gray.400"}
            borderBottomLeftRadius={i === 0 ? "lg" : "none"}
            borderBottomRightRadius={i === 2 ? "lg" : "none"}
          >
            <Text noOfLines={2} lineHeight={1.1} align="center">
              {subBoxCurrentTexts[i]}<br />
              {subBoxAngleTexts[i]}
            </Text>
          </Center>
        ))}
      </Flex>

      {/* AlertDialog */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {enableAlarms ? "Desactivar" : "Activar"} medidor {clientID}m{meterID}
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Seguro que deseas {enableAlarms && "des"}activar el <b>medidor {clientID}m{meterID}</b>?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme={enableAlarms ? "blue" : "yellow"} onClick={() => setAlarms().then(onClose)} ml={3}>
                {enableAlarms ? <MoonIcon mr={2} /> : <BellIcon mr={2} />}
                {enableAlarms ? "Desactivar" : "Activar"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </VStack>
  )
}