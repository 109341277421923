import { useState } from "react";
import { AxiosError } from "axios";

import { useToast, Button, Center, Heading, Image, Input, InputGroup, InputLeftElement, VStack } from "@chakra-ui/react";
import { AtSignIcon, LockIcon } from "@chakra-ui/icons";
import { extractAdminClaims, storeJWT } from "../utils/jwt";
import { login } from "../utils/api";
import { AdminState } from "../utils/interfaces";
import getLogoSource from "../utils/getLogoSource";


interface LoginParams {
  setAdminState: (newAdminState: React.SetStateAction<AdminState>) => void;
}

export default function LoginScreen({ setAdminState }: LoginParams) {
  const toast = useToast();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setIsLoggingIn(true);

    const { username, password } = document.forms[0];
    
    login(username.value, password.value)
    .then(res => {
      storeJWT(res.data.token);
      setAdminState({ isValidating: false, admin: extractAdminClaims(res.data.token) });
    })
    .catch((err: AxiosError) => {
      var message = "";
      // Si el backend retornó un error (código no 2xx)
      if (err.response) {
        const data = err.response.data as any;
        // Deberían haber datos, ¿esto no debería pasar?
        if (!data) message = "Error desconocido: la respuesta del servidor no contiene datos.";
        else if (data.message) message = data.message;
        else if (data.error) message = data.error;
        else message = data;
      // Si el backend no dio respuesta
      } else if (err.request) {
        message = "Error de conexión con el servidor."
      // Si algo más salió mal
      } else {
        message = err.message;
      }
      toast({
        title: "Error",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    })
    .finally(() => setIsLoggingIn(false));
  };

  return (
    <Center height={window.innerHeight} bg="teal.500">
      <form method="post" onSubmit={handleSubmit}>
        <VStack borderRadius="md" p={{"base": "8", "md": "12"}} bg="white" spacing="6" shadow="xl">
          <Image src={getLogoSource()} width={{"base": "250px", "md": "300px"}} />
          <Heading>Semáforo MeterCon</Heading>
          <Heading as="h3">Iniciar sesión</Heading>

          {/* Campo de nombre de usuario */}
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<AtSignIcon/>} />
            <Input isRequired name="username" placeholder="Nombre de usuario" borderColor="gray.500"/>
          </InputGroup>
          
          {/* Campo de contraseña */}
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<LockIcon/>} />
            <Input isRequired name="password" type="password" placeholder="Contraseña" borderColor="gray.500"/>
          </InputGroup>

          <Button isLoading={isLoggingIn} type="submit" colorScheme="teal" shadow="lg">Ingresar</Button>
        </VStack>
      </form>
    </Center>
  );
};
