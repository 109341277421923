import React from "react";
import { Box, Button, Center, Flex, Grid, GridItem, Heading, HStack, IconButton, Image, Input, Link, SimpleGrid, Spinner, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import ConfigFormModal from "./configFormModal/ConfigFormModal";
import { DaySearch, SemaphoreConfig, ServerStatus } from "../../utils/interfaces";
import ServerClock from "./serverClock/ServerClock";
import getLogoSource from "../../utils/getLogoSource";


interface HeaderProps {
  config: SemaphoreConfig;
  setConfig: (newConfig: React.SetStateAction<SemaphoreConfig>) => void;
  softwareVersion: string;
  serverStatus: ServerStatus;
  numEnabledMeters: number;
  numBadMeters: number;
  numSuspiciousMeters: number;
  daySearch: DaySearch;
  setDaySearch: (newSearch: React.SetStateAction<DaySearch>) => void;
};

export default function Header({ config, setConfig, softwareVersion, serverStatus, numEnabledMeters, numBadMeters, numSuspiciousMeters, daySearch, setDaySearch }: HeaderProps): JSX.Element {
  // Para abrir y cerrar el formulario (ConfigFormModal)
  const { isOpen, onOpen, onClose } = useDisclosure();
  var localMarginDays = daySearch.days;

  function createColorTimeString(keyPrefix: "yellowTime" | "redTime" | "cyanTime") {
    const days = config[(keyPrefix + "Days") as keyof SemaphoreConfig];
    const hours = config[(keyPrefix + "Hours") as keyof SemaphoreConfig];
    const minutes = config[(keyPrefix + "Minutes") as keyof SemaphoreConfig];
    const seconds = config[(keyPrefix + "Seconds") as keyof SemaphoreConfig];

    if (days !== 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
    if (hours !== 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    if (minutes !== 0) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  }

  function onChangeFloat(event: React.ChangeEvent<HTMLInputElement>) {
    // event.target se refiere al input en sí (Input, TimeInput, Radio)
    localMarginDays = parseFloat(event.target.value);
  }

  function onPressEnter(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      searchRange();
    }
  }

  function searchRange() {
    // El atributo isLoading cambiará en HomeScreen cuando se termine de cargar los datos.
    // toggleMeAtSearch invierte su valor cada vez que se inicia una búsqueda: ¿por qué no usar
    // directamente isLoading como dependencia en HomeScreen? Porque si se usara isLoading, cuando
    // cambie a false al terminar la búsqueda, se desencadenaría una segunda búsqueda innecesaria.
    setDaySearch({ enabled: true, isLoading: true, days: localMarginDays, toggleMeAtSearch: !daySearch.toggleMeAtSearch });
  }

  function cancelSearch() {
    setDaySearch({ enabled: false, isLoading: false, days: localMarginDays, toggleMeAtSearch: daySearch.toggleMeAtSearch });
  }

  function LegendBox({ prefix, time, color }: { prefix: string, time: "yellowTime" | "redTime" | "cyanTime", color: string }) {
    return (
      <Center w={{ "base": "180px", "sm": "200px", "md": "240px", "lg": "220px" }} py={{ "base": 1, "md": 2, "lg": 1 }} borderRadius="md" bg={`${color}.500`} color="white" shadow="sm">
        {`${prefix} ${createColorTimeString(time)}`}
      </Center>
    )
  }

  return (
    <VStack w="full" spacing={3}>

      {/*
      Título y logo
      */}
      <Grid templateAreas={{
        "base": `"logo   title"
                 "desc   desc"
                 "conf   mode"
                 "legend legend"`,

        "md": `"logo   title  title"
                 "logo   desc   desc"
                 "legend legend conf"
                 "legend legend mode"`,

        "lg": `"logo   title  conf"
                 "logo   desc   mode"
                 "legend legend legend"`
      }}
        columnGap={{ "base": 2, "md": 2, "lg": 4 }}
        rowGap={2}
        justifyItems="center"
        alignItems="center"
      >
        <GridItem area="logo" justifyItems="center">
          <Link href="https://smartgrid.uc.cl/" target="_blank" rel="noopener">
            <Image
              w={{ "base": "140px", "sm": "180px", "md": "230px", "lg": "260px" }}
              src={getLogoSource()}>
            </Image>
          </Link>
        </GridItem>
        <GridItem area="title">
          <Heading textAlign="center" as="h1" size="lg">
            HV Semáforo v{softwareVersion}
          </Heading>
        </GridItem>
        <GridItem area="desc">
          <Text align="center" fontSize={{ "base": "sm", "sm": "sm", "md": "md" }} lineHeight={1.2}>
            Funcionamiento de equipos Metercon y medidores en UC<br />
            Registra la última comunicación con el servidor de datos
          </Text>
        </GridItem>
        <GridItem area="conf">
          <Button onClick={onOpen} shadow="lg">Configuración</Button>
          <ConfigFormModal isOpen={isOpen} onClose={onClose} config={config} setConfig={setConfig} />
        </GridItem>
        <GridItem area="mode">
          <Center w="134px" py="2" mr={{ "base": "32px", "md": 0 }} borderRadius="md" bg={daySearch.enabled ? "orange.100" : "cyan.100"}>
            Modo: {daySearch.enabled ? "Normal" : "Light"}
          </Center>
        </GridItem>

        {/*
        Leyenda: los colores que indican cada estado de las MeterBoxes

        Se leen los valores de config para indicar qué mostrar en cada caja

        Ejemplo: si redTimeDays === 1, redTimeHours === 12, redTimeMinutes === 30
        y redTimeSeconds === 0, entonces en la tercera caja (color rojo) se
        muestra "Retardo > 1d 12h 30m 0s": ese es el tiempo para que un
        dispositivo sea marcado como "rojo"
        */}
        <GridItem area="legend">
          <SimpleGrid columns={{ "base": 2, "lg": 4 }} rowGap={{ "base": 1, "md": 2 }} columnGap={{ "base": 1, "sm": 3, "lg": 4 }}>
            <LegendBox prefix="Retardo <" time="yellowTime" color="green" />
            <LegendBox prefix="Retardo >" time="yellowTime" color="yellow" />
            <LegendBox prefix="Retardo >" time="redTime" color="red" />
            <LegendBox prefix="Adelanto >" time="cyanTime" color="cyan" />
          </SimpleGrid>
        </GridItem>
      </Grid>

      {/*
      Información del servidor
      Acá se muestra la hora (serverTime) y si hubo error (hasError)
      También hay un botón para seleccionar un rango de días de búsqueda
      */}
      <SimpleGrid columns={{ "base": 1, "md": 2 }} rowGap="2" columnGap={{ "md": 4, "lg": 12 }}>
        <ServerClock serverStatus={serverStatus} />
        <Flex w={{ "base": "364px", "sm": "412px", "md": "320px", "lg": "440px" }} gap="4">
          <Center w="80px">
            {daySearch.enabled
              ? daySearch.isLoading
                ? <Spinner color="teal"></Spinner>
                : <IconButton aria-label="Cancelar" colorScheme="red" icon={<CloseIcon />} onClick={cancelSearch} shadow="lg" />
              : <IconButton aria-label="Cancelar" isDisabled={true} colorScheme="gray" icon={<CloseIcon />} shadow="lg" />}
          </Center>
          <Input name="marginDays" type="number" defaultValue={daySearch.days} onChange={onChangeFloat} onKeyDown={onPressEnter}></Input>
          <Button px="6" colorScheme={daySearch.isLoading ? "gray" : "cyan"} isDisabled={daySearch.isLoading} onClick={searchRange} shadow="lg">Buscar</Button>
        </Flex>
      </SimpleGrid>

      {/*
      Información general de dispositivos
      Se muestra la cantidad de dispositivos conectados (numMeters - numBadMeters)
      y la cantidad de dispositivos rojos y amarillos (numBadMeters)
      */}
      <SimpleGrid columns={{ "base": 1, "md": 2 }} spacing={{ "base": 2, "md": 3, "lg": "114px" }}>
        {/* Dispositivos conectados */}
        <HStack spacing="0" align="center" shadow="sm">
          <Box px={{ "base": 6, "sm": 9, "md": 4, "lg": 12 }} py="1" borderRadius="md" bg="green.500" color="white">
            <Text fontSize="xl"><b>Dispositivos conectados:</b></Text>
          </Box>
          <Text pl="4" fontSize="xl"><b>{numEnabledMeters - numBadMeters}</b></Text>
        </HStack>

        {/* Dispositivos desconectados */}
        <HStack spacing="0" align="center" shadow="sm">
          <Box pl={{ "base": 2, "sm": 5, "md": 4, "lg": 8 }} pr="0" py="1" borderLeftRadius="md" bg="yellow.500" color="white">
            <Text fontSize="xl"><b>Dispositi</b></Text>
          </Box>
          <Box px="0" py="1" bg="red.500" color="white">
            <Text fontSize="xl"><b>vos descon</b></Text>
          </Box>
          <Box pl="0" pr={{ "base": 2, "sm": 5, "md": 4, "lg": 8 }} py="1" borderRightRadius="md" bg="gray.500" color="white">
            <Text fontSize="xl"><b>ectados:</b></Text>
          </Box>
          <Text pl="4" fontSize="xl"><b>{numBadMeters}</b></Text>
        </HStack>
      </SimpleGrid>

      <Heading px="2" as="h3" size={{ "base": "md", "lg": "lg" }} textAlign="center">
        Medidores con ángulos y/o voltajes sospechosos (rojos o grises): {numSuspiciousMeters}
      </Heading>

    </VStack>
  )
}
