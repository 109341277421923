import { GETDataResponseBody, Meter, SemaphoreConfig } from "../utils/interfaces";
import unixTimeToDateTime from "../utils/unixTimeToDateTime";


/**
 * generateMeterData procesa statusData, un arreglo de datos por usuario.
 * Por cada usuario, genera los datos que se deben mostrar en la página:
 * el color de las cajas (MeterBox), y qué texto deben mostrar. Toda esa
 * información se guarda en un nuevo arreglo de objetos, newAllMeters.
 */
export default function generateMeters(data: GETDataResponseBody, config: SemaphoreConfig): Meter[] {
  return data.unixTimes.map((meterUnixTime, i) => {
    const clientID = data.clientIDs[i];
    const meterID = data.meterIDs[i];
    const companyID = data.companyIDs[i];
    const enableAlarms = data.enableAlarms[i];

    const boxHeading = `${clientID}m${meterID}`;

    const company = data.companies.find(c => c.id === companyID);
    const domainName = company?.domain_name ?? "";

    // Si el tiempo es 0 los datos son invalidos
    if (meterUnixTime === 0) {
      return {
        clientID: clientID,
        meterID: meterID,
        companyID: companyID,
        delay: 0,
        domainName: domainName,

        boxBGColor: "gray.500",
        boxHeading: boxHeading,
        boxTime: "Sin datos",

        subBoxBGColors: ["gray.400", "gray.400", "gray.400"],
        subBoxTextColors: ["white", "white", "white"],
        subBoxCurrentTexts: ["", "", ""],
        subBoxAngleTexts: ["", "", ""],

        enableAlarms: enableAlarms,
      };
    }

    // Retardo (qué tan atrasada está la última fecha del software, respecto de
    // la fecha del servidor o la fecha en que se envió el dato)
    // En base a este retardo se asigna un color a MeterBox
    const delay = data.serverUnixTime - meterUnixTime;

    /*
    Estilo de MeterBox
    
    Adelanto > 10m    (o cyanTime):    Celeste
    Retardo  < 10m 0s (o yellowTime):  Verde
    Retardo  > 10m 0s (o yellowTime):  Amarillo
    Retardo  > 24h 0m (o redTime):     Rojo
    */
    var boxBGColor = "";
    if (Object.keys(config).length === 0) {
      boxBGColor = "gray.500";
    } else {
      const yellowTime = 24*60*60*config.yellowTimeDays + 60*60*config.yellowTimeHours + 60*config.yellowTimeMinutes + config.yellowTimeSeconds;
      const redTime = 24*60*60*config.redTimeDays + 60*60*config.redTimeHours + 60*config.redTimeMinutes + config.redTimeSeconds;
      const cyanTime = 24*60*60*config.cyanTimeDays + 60*60*config.cyanTimeHours + 60*config.cyanTimeMinutes + config.cyanTimeSeconds;

      // Adelanto > 10m
      if (delay < -cyanTime) {
        boxBGColor = "cyan.500";
      }
      // Retardo < 10m 0s
      else if (delay < yellowTime) {
        boxBGColor = "green.500";
      }
      // Retardo > 10m 0s (o sea, < 24h 0m)
      else if (delay < redTime) {
        boxBGColor = "yellow.500";
      }
      // Retardo > 24h 0m
      else {
        boxBGColor = "red.500";
      }
    }

    /*
    Contenido de MeterBox

    Ejemplo:

    1m1                   (boxHeading)
    Última fecha válida:
    2/3/2023, 15:10:59    (boxTime)
    */
    const boxTime = unixTimeToDateTime(meterUnixTime);

    /*
    Estilo y contenido de MeterSubBox
    
    Estado == "Operando":  Verde    | Mostrar estado
    Estado != "Operando":  Amarillo | Mostrar estado
    ALERTA:                Rojo     | MOSTRAR ALERTA
    */
    const angles = [data.maxCurrents1Angles[i], data.maxCurrents2Angles[i], data.maxCurrents3Angles[i]];
    const currents = [data.maxCurrents1[i], data.maxCurrents2[i], data.maxCurrents3[i]];
    const minVoltages = [data.minVoltages1[i], data.minVoltages2[i], data.minVoltages3[i]];
    const maxVoltages = [data.maxVoltages1[i], data.maxVoltages2[i], data.maxVoltages3[i]];

    const subBoxBGColors = ["", "", ""];
    const subBoxTextColors = ["", "", ""];
    const subBoxCurrentTexts = ["", "", ""];
    const subBoxAngleTexts = ["", "", ""];

    for (var subIndex = 0; subIndex < 3; subIndex++) {
      subBoxCurrentTexts[subIndex] = `${currents[subIndex]} A`;

      if (minVoltages[subIndex] < config.minVoltage) {
        // Voltaje fuera del rango: rojo
        subBoxBGColors[subIndex] = "red.400";
        subBoxTextColors[subIndex] = "white";
        subBoxAngleTexts[subIndex] = `${minVoltages[subIndex]} V`;
      }
      else if (maxVoltages[subIndex] > config.maxVoltage) {
        // Voltaje fuera del rango: rojo
        subBoxBGColors[subIndex] = "red.400";
        subBoxTextColors[subIndex] = "white";
        subBoxAngleTexts[subIndex] = `${maxVoltages[subIndex]} V`;
      }
      else if (currents[subIndex] < config.minCurrent) {
        // Corriente por debajo del umbral: amarillo
        subBoxBGColors[subIndex] = "yellow.400";
        subBoxTextColors[subIndex] = "black";
        subBoxAngleTexts[subIndex] = `?º`;
      }
      else {
        // Actualmente los ángulos están en el rango [0°, 360°[
        // El tramo ]180°, 360°[ se debe trasladar al tramo ]-180°, 0°[
        // restándole 360°, logrando así estandarizar los ángulos al rango
        // ]-180°, 180°]
        if (angles[subIndex] > 180) {
          angles[subIndex] -= 360;
        }

        if (angles[subIndex] < config.minAngle || angles[subIndex] > config.maxAngle) {
          // Angulo fuera del rango: rojo 
          subBoxBGColors[subIndex] = "red.400";
        } else {
          // Todo bien, todo normal: verde
          subBoxBGColors[subIndex] = "green.400";
        }
        
        // toFixed(2) convierte angles[i] a un string con 2 decimales fijos
        // El + antes del string vuelve a convertirlo a número, permitiendo
        // que strings como 1.50 se muestren como 1.5 en vez de 1.50
        // Cosas de JavaScript
        subBoxTextColors[subIndex] = "white";
        subBoxAngleTexts[subIndex] = `${+angles[subIndex].toFixed(2)}º`;
      }
    }
    
    // Objeto con todos los datos del dispositivo a mostrar en la página
    return {
      clientID: clientID,
      meterID: meterID,
      companyID: companyID,
      delay: delay,
      domainName: domainName,

      boxBGColor: boxBGColor,
      boxHeading: boxHeading,
      boxTime: boxTime,

      subBoxBGColors: subBoxBGColors,
      subBoxTextColors: subBoxTextColors,
      subBoxCurrentTexts: subBoxCurrentTexts,
      subBoxAngleTexts: subBoxAngleTexts,

      enableAlarms: enableAlarms,
    };
  });
}