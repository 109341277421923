import { useState, useEffect, useCallback } from "react";
import { Box, Center, CircularProgress, Text, VStack } from "@chakra-ui/react";

import Header from "./header/Header";
import MeterSection from "./meterSection/MeterSection";

import { getHomeData, getSemaphoreConfig } from "../utils/api";
import generateMeters from "./generateMeterData";
import { DaySearch, Meter, SemaphoreConfig, ServerStatus } from "../utils/interfaces";

const DEFAULT_CONFIG: SemaphoreConfig = {
  minCurrent: 0.2,
  minAngle: -40,
  maxAngle: 30,
  minVoltage: 210,
  maxVoltage: 240,

  yellowTimeDays: 0,
  yellowTimeHours: 0,
  yellowTimeMinutes: 10,
  yellowTimeSeconds: 0,

  redTimeDays: 1,
  redTimeHours: 0,
  redTimeMinutes: 0,
  redTimeSeconds: 0,

  cyanTimeDays: 0,
  cyanTimeHours: 0,
  cyanTimeMinutes: 10,
  cyanTimeSeconds: 0,

  mailEnable: false,
  mailRecipients: "",
  mailTime: "08:00",
  useSendGrid: false,

  marginDays: 1,
  maxMarginDays: 15,
};

export default function HomeScreen(): JSX.Element {
  const [config, setConfig] = useState(DEFAULT_CONFIG);
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [serverStatus, setServerStatus] = useState<ServerStatus>({ hasError: false, isLoading: true, unixTime: 0 });
  const [allMeters, setAllMeters] = useState<Meter[]>([]);
  const [daySearch, setDaySearch] = useState<DaySearch>({ enabled: false, isLoading: false, days: 1, toggleMeAtSearch: false });
  
  const [reloadKey, setReloadKey] = useState(0);
  
  const allEnabledMeterIndices: number[] = [];
  const badEnabledMeterIndices: number[] = [];
  const disabledMeterIndices: number[] = [];

  // Función reutilizable para pedir toda la información al backend
  const getAllMeterData = useCallback((config: SemaphoreConfig) => {
    // GET /data invoca a una función definida en semaphoreback/main.go,
    // que obtiene todos los usuarios y todos datos de la tabla status_data en
    // la base de datos, junto con un tiempo (unix_time) indicando la fecha de
    // obtención de datos en segundos desde el epoch (busca "Unix Time")
    getHomeData(daySearch)
      .then(res => {
        const newAllMeterData = generateMeters(res.data, config);
        setServerStatus({ hasError: false, isLoading: false, unixTime: res.data.serverUnixTime });
        setAllMeters(newAllMeterData);
        setDaySearch({ enabled: daySearch.enabled, isLoading: false, days: daySearch.days, toggleMeAtSearch: daySearch.toggleMeAtSearch });
      })
      .catch(() => {
        setServerStatus({ hasError: true, isLoading: false, unixTime: serverStatus.unixTime });
      });

  }, [daySearch.enabled, daySearch.days, daySearch.toggleMeAtSearch]);

  const getConfig = useCallback(() => {
    var data = { version: "", config: DEFAULT_CONFIG };
    getSemaphoreConfig()
      .then(res => data = res.data)
      .catch(() => data = { version: "?", config: DEFAULT_CONFIG })
      .finally(() => {
        setSoftwareVersion(data.version);
        setConfig(data.config);
        getAllMeterData(data.config);
      });
  }, [getAllMeterData]);

  useEffect(getConfig, [getConfig]);
  useEffect(() => {
    if (daySearch.enabled) {
      return () => { };
    }
    // Cada 30 segundos, volver a pedir todos los dispositivos
    const interval = setInterval(() => {
      if (serverStatus.hasError) {
        setServerStatus({ hasError: true, isLoading: true, unixTime: serverStatus.unixTime });
        getConfig();
      } else {
        getAllMeterData(config);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [config, getConfig, serverStatus.hasError, daySearch.enabled, getAllMeterData]);

  
  const reloadDeviceIndices = (updateReloadKey: boolean) => {
    badEnabledMeterIndices.splice(0, badEnabledMeterIndices.length);

    allMeters.forEach((meter, i) => {
      if (meter.enableAlarms) {
        allEnabledMeterIndices.push(i);
        // Para la sección de dispositivos caídos (en DeviceSection), obtener todos
        // los dispositivos que tengan algún color amarillo o rojo
        if (meter.boxBGColor.startsWith("yellow") ||
          meter.boxBGColor.startsWith("red") ||
          meter.boxBGColor.startsWith("gray")
        ) {
          badEnabledMeterIndices.push(i);
        }
      } else {
        disabledMeterIndices.push(i);
      }
    });

    // Ordenarlos de mayor a menor retardo
    badEnabledMeterIndices.sort((i, j) => {
      const d1 = allMeters[i];
      const d2 = allMeters[j];
      return (d2.delay - d1.delay) + 0.5*(d2.clientID - d1.clientID);
    });
    
    if (updateReloadKey) setReloadKey(reloadKey + 1);
  };

  reloadDeviceIndices(false);

  if (Object.keys(config).length === 0) {
    return (
      <Center height={window.innerHeight}>
        <VStack spacing={8}>
          <CircularProgress isIndeterminate size={150} color="teal" />
          <Text fontSize="2xl">Cargando datos...</Text>
        </VStack>
      </Center>
    );
  }

  var numSuspiciousMeters = 0;
  for (const meter of allMeters) {
    if (!meter) {
      continue;
    }
    for (const subBoxColor of meter.subBoxBGColors) {
      if (subBoxColor.startsWith("red") || subBoxColor.startsWith("gray")) {
        numSuspiciousMeters++;
        break;
      }
    }
  }

  return (
    <Box w="full" px={2} py={{ "base": 3, "lg": 5 }}>
      <VStack spacing={{ "base": 2, "md": 4, "lg": 6 }} align="center">
        <Header
          config={config}
          setConfig={setConfig}
          softwareVersion={softwareVersion}
          serverStatus={serverStatus}
          numEnabledMeters={allEnabledMeterIndices.length}
          numBadMeters={badEnabledMeterIndices.length}
          numSuspiciousMeters={numSuspiciousMeters}
          daySearch={daySearch}
          setDaySearch={setDaySearch}
        />
        {allMeters !== undefined &&
          <MeterSection
            allMeters={allMeters}
            allEnabledMeterIndices={allEnabledMeterIndices}
            badEnabledMeterIndices={badEnabledMeterIndices}
            disabledMeterIndices={disabledMeterIndices}
            reloadDeviceIndices={reloadDeviceIndices}
          />}
      </VStack>
    </Box>
  );
}